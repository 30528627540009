@charset "utf-8"

$primary: #1b5169
$secondary: #3b9cc9

@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap')
    
// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass'
@import '../node_modules/bulma/sass/base/_all.sass'

@import "../node_modules/bulma/sass/grid/columns.sass"
@import "../node_modules/bulma/sass/grid/tiles.sass"
@import "../node_modules/bulma/sass/helpers/_all.sass"

@import '../node_modules/bulma/sass/layout/hero.sass'
@import '../node_modules/bulma/sass/layout/section.sass'
@import '../node_modules/bulma/sass/layout/footer.sass'

@import '../node_modules/bulma/sass/form/_all.sass'

@import '../node_modules/bulma/sass/components/navbar.sass'
@import '../node_modules/bulma/sass/components/modal.sass'
@import '../node_modules/bulma/sass/components/breadcrumb.sass'
@import '../node_modules/bulma/sass/components/level.sass'
@import '../node_modules/bulma/sass/components/tabs.sass'
@import '../node_modules/bulma/sass/components/media.sass'
@import '../node_modules/bulma/sass/components/card.sass'

@import '../node_modules/bulma/sass/elements/button.sass'
@import '../node_modules/bulma/sass/elements/image.sass'
@import '../node_modules/bulma/sass/elements/container.sass'
@import '../node_modules/bulma/sass/elements/box.sass'
@import '../node_modules/bulma/sass/elements/tag.sass'
@import '../node_modules/bulma/sass/elements/content.sass'
@import '../node_modules/bulma/sass/elements/title.sass'
@import '../node_modules/bulma/sass/elements/progress.sass'
@import '../node_modules/bulma/sass/elements/notification.sass'

.title
    font-family: "Luckiest Guy", "Open Sans", sans-serif

.breadcrumb 
    ol
        flex-wrap: nowrap
    li.is-active
        overflow: hidden
        a span 
            text-overflow: ellipsis


.column.ad
    min-height: 250px

.CookieConsent
    height: auto
    position: fixed
    bottom: 0px
    background-color: #212121
    color: #fff
    padding: 0.5rem
    width: 100%
    text-align: center
    .cookie-button-wrapper 
        width: 100%
        display: flex
        justify-content: flex-end

// Masonry 


/* 2 columns */
.grid-item--width2 
    width: 40%
.grid-item--widthfull 
    width: 100%

.content ul.social
    list-style-type: none
    padding: 0
    margin: 0

.deal .card-image
    background-color: #212121
    .image 
        opacity: 0.5

.table__wrapper 
    overflow-x: auto

.hero .humbleBadge
    position: absolute
    top: 0
    right: 0
    opacity: 0.5

.card-image .logo
    position: absolute
    top: 0px
    left: 0px
    padding: 1rem
    display: flex
    align-content: center
    justify-content: center
    flex-direction: column
    height: 100%

.pk_title 
    border-bottom: 5px solid $primary

body 
    font-family: 'Open Sans', sans-serif

iframe 
    max-width: 100%

.mainbody
    min-height: 82vh

.fullwidth 
    width: 100%
    text-align: center
    display: block

.card-footer-item .level
    width: 100%

.zindex-10 
    z-index: 10

.flex-column
    flex-direction: column

.loading 
    height: 90vh

.breadcrumb
    padding: .7rem
    text-transform: capitalize

.navbar-burger
    background: transparent
    border: 0 
    outline: 0

.graphics
    position: relative
    top: 47px
    .cloud
        position: absolute
        bottom: -38px
        right: -60px
    .cloud2
        position: absolute
        bottom: -38px
        left: -60px
        transform: scaleX(-1)

.WelcomeSearch
    background: url("./assets/bg.svg") repeat-x bottom center
    font-family: VT323, "Open Sans", sans-serif
    font-size: 2rem
    .searchBox input
        border: 4px solid #1ebc9e
    .hero-body
        align-items: normal!important
    .custombg
        background: url("./assets/buttons.png") no-repeat
        background-size: cover
        max-width: 148px
        width: 100%
        min-width: 100px
        height: auto
        font-family: VT323, "Open Sans", sans-serif
        &:active
            background-position: -148px 0
            outline: none
            border: none
        &:focus
            outline: none
            border: none
            box-shadow: none

.hero
    position: relative
    overflow: hidden
    .bgimage
            position: absolute
            right: 10px
            bottom: -50%
            height: 200%
            filter: opacity(0.2) contrast(0.5)
            transform: rotate(25deg)

.infodisplay 
    .icon
        font-size: 1.6rem

.k-green
    color: #05ce78

.p-blue
    color: #002c49

.kofi-red
    color: #FF5E5B

.fundingBox li
    font-size: 4rem

.funding
    font-size: 2rem
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    background: rgba(255,255,255,0.8)

.card.items 
    align-content: space-between
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%
    .card-image
        position: relative
        .img-flex 
            position: absolute
            display: flex
            left: 0
            top: 0
            width: 100%
            bottom: 0
            right: 0
            flex-direction: column
            text-align: center
            border-radius: 4px
            border: 1px solid #8a8a8a
            img 
                padding: 0
                margin: 0 auto
                border-radius: 4px
    figure
        border-radius: 4px
        border: 1px solid #8a8a8a
        img
            border-radius: 5px
            background-color: rgba(27,81,105,0.5)
            filter: blur(1px)

.card-content.padding-1
    padding: .3rem .5rem

.infodisplay
    .title 
        color: $primary
        margin-top: 1rem
    .titlebox
        margin: .3rem 0 .6rem
        text-transform: capitalize

.title
    font-family: "Luckiest Guy", sans-serif
    letter-spacing: .1rem 

.links a
    font-size: 1.7rem

.img-flex
    display: flex
    width: 100%
    height: 100%
    align-content: center
    justify-content: center


.first 
    @media (min-width: $tablet)
        order: 1

.embed-posts li 
    position: relative
    border-bottom: 1px solid #ccc
    padding-bottom: 1rem
    &:last-child
        border-bottom: none
    .date 
        position: absolute
        right: 0px
        top: -15px
        font-weight: bold
        color: #ccc

.boxhead
    margin-top: 0!important

.contentarea
    margin: 2rem 0

// WORDPRESS CORE
.alignnone 
    margin: 5px 20px 20px 0

.aligncenter,
div.aligncenter 
    display: block
    margin: 5px auto 5px auto

.mainbody img 
    border-radius: 4px
    @media screen and (max-width: 768px)
        display: block
        margin: 0 auto
        margin-bottom: 2rem

.alignright 
    @media screen and (min-width: 768px)
        float: right
        margin: 5px 0 20px 20px

.alignleft 
    @media screen and (min-width: 768px)
        float: left
        margin: 5px 20px 20px 0


a img.alignright 
    @media screen and (min-width: 768px)
        float: right
        margin: 5px 0 20px 20px


a img.alignnone 
    margin: 5px 20px 20px 0


a img.alignleft 
    @media screen and (min-width: 768px)
        float: left
        margin: 5px 20px 20px 0


a img.aligncenter 
    display: block
    margin-left: auto
    margin-right: auto


.wp-caption 
    background: #fff
    border: 1px solid #ccc
    max-width: 100% 
    text-align: center
    border-radius: 4px
    @media screen and (min-width: 768px)
        padding: 5px 3px 10px
        max-width: 96% 


.wp-caption.alignnone 
    margin: 5px 20px 20px 0


.wp-caption.alignleft 
    margin: 0 auto 20px
    @media screen and (min-width: 768px)
        margin: 5px 20px 20px 0


.wp-caption.alignright 
    margin: 0 auto 20px
    @media screen and (min-width: 768px)
        margin: 5px 0 20px 20px


.wp-caption img 
    border: 0 none
    height: auto
    margin: 0
    max-width: 98.5%
    padding: 0
    width: auto
    border-radius: 4px


.wp-caption p.wp-caption-text 
    font-size: 11px
    line-height: 17px
    margin: 0
    padding: 4px 4px 5px


/* Text meant only for screen readers. */
.screen-reader-text 
    border: 0
    clip: rect(1px, 1px, 1px, 1px)
    clip-path: inset(50%)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute !important
    width: 1px
    word-wrap: normal !important 


.screen-reader-text:focus 
    background-color: #eee
    clip: auto !important
    clip-path: none
    color: #444
    display: block
    font-size: 1em
    height: auto
    left: 5px
    line-height: normal
    padding: 15px 23px 14px
    text-decoration: none
    top: 5px
    width: auto
    z-index: 100000
    /* Above WP toolbar. */


// Article Typography

.article 
    line-height: calc(1.5em + .2vw)
    font-size: calc(.35842vw + .95296em)

.countdown.notification 
    padding: 5px
    margin-bottom: 0px
    font-weight: bold